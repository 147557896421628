import React, { useState, useRef, useEffect } from 'react';
import { GiEmerald } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import { useBet } from '../../hooks/useBet';
import { useVolume } from "../../contexts/VolumeContext";
import { toast } from 'react-toastify';
import KenoBettingWindow from './KenoBettingWindow';
import './Keno.css';
import diamond1 from '../../assets/diamond1.mp3';
import diamond2 from '../../assets/diamond2.mp3';
import diamond3 from '../../assets/diamond3.mp3';
import diamond4 from '../../assets/diamond4.mp3';
import diamond5 from '../../assets/diamond5.mp3';
import diamond6 from '../../assets/diamond6.mp3';
import diamond7 from '../../assets/diamond7.mp3';
import kenosound from '../../assets/kenosound.mp3';
import bankpinfail from '../../assets/bankpinfail.mp3';
import KenoPayoutTable from './KenoPayoutTable';
import { fetchAndUpdateBalance, supabase } from "../../api/supabaseClient.js";
import KenoWinningsComponent from './KenoWinningsComponent';

const Keno = () => {
    const { signedIn, session } = useAuth();
    const {
        bet,
        setBet,
        handleBetChange,
        handleBetBlur,
        handleHalfBet,
        handleDoubleBet,
    } = useBet(0);

    // State management
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [drawnNumbers, setDrawnNumbers] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [game, setGame] = useState(null);
    const [multiplier, setMultiplier] = useState(1.0);
    const [nextMultiplier, setNextMultiplier] = useState(1.0);
    const [autoCashoutAmount, setAutoCashoutAmount] = useState(0.0);
    const [autoMultiplier, setAutoMultiplier] = useState(1.0);
    const [winnings, setWinnings] = useState(0.0);
    const [mode, setMode] = useState('Manual');
    const [numberOfGames, setNumberOfGames] = useState(0);
    const [gamesPlayed, setGamesPlayed] = useState(0);
    const [autoSelectedNumbers, setAutoSelectedNumbers] = useState([]);
    const [recovering, setRecovering] = useState(false);
    const [shake, setShake] = useState(false);
    const [showPlayAgain, setShowPlayAgain] = useState(false);
    const [autoPlayIntervalId, setAutoPlayIntervalId] = useState(null);
    const isActiveRef = useRef(false);
    const [speed, setSpeed] = useState(1);
    const speedRef = useRef(speed);
    const [currentDrawIndex, setCurrentDrawIndex] = useState(0);
    const [risk, setRisk] = useState('Low');
    const diamondSoundIndex = useRef(0);
    const drawIntervalRef = useRef(null);

    const diamondSounds = [diamond1, diamond2, diamond3, diamond4, diamond5, diamond6, diamond7];
    const bankPinSound = new Audio(bankpinfail);
    const { MAXBET, balanceType, fetchBalances, getActiveBalance } = useBalance();
    const { mutedStates, volumes } = useVolume();

    const moneyRef = useRef(getActiveBalance());
    useEffect(() => {
        moneyRef.current = getActiveBalance();
    }, [getActiveBalance()]);

    useEffect(() => {
        speedRef.current = speed;
    }, [speed]);


    const playSound = () => {
        const sound = new Audio(diamondSounds[diamondSoundIndex.current]);
        const volume = mutedStates['keno'] ? 0 : parseFloat(volumes['keno']);
        sound.volume = Math.min(Math.max(volume, 0), 1);
        sound.play()
            .then(() => {
                // Increment sound index after successful play
                diamondSoundIndex.current = (diamondSoundIndex.current + 1) % diamondSounds.length;
            })
            .catch(error => console.log('Error playing sound:', error));
    };

    const startGame = async () => {
        if (bet > moneyRef.current) {
            toast.error("Insufficient balance");
            bankPinSound.play();
            setShake(true);
            setTimeout(() => setShake(false), 300);
            return false;
        }

        if (selectedNumbers.length === 0) {
            toast.error("Please select at least one number");
            return false;
        }

        try {
            diamondSoundIndex.current = 0;
            setIsActive(true);
            setGameOver(false);
            setDrawnNumbers([]);
            setCurrentDrawIndex(0);

            const { data, error } = await supabase.rpc("start_keno", {
                v_player_id: session?.user.id,
                v_selected_grid: selectedNumbers,
                v_bet: bet,
                v_type: balanceType,
                v_risk: risk
            });

            if (error) {
                console.error("Error starting game:", error);
                toast.error(error.message);
                setIsActive(false);
                return false;
            }

            setGame(data.game);

            // Clear any existing interval
            if (drawIntervalRef.current) {
                clearInterval(drawIntervalRef.current);
            }

            // Start drawing numbers
            let index = 0;
            const drawnNums = data.drawn_numbers;

            return new Promise((resolve) => {
                drawIntervalRef.current = setInterval(() => {
                    if (index < drawnNums.length) {
                        const newNumber = drawnNums[index];
                        setDrawnNumbers(prev => [...prev, newNumber]);
                        setCurrentDrawIndex(index + 1);

                        const isHit = selectedNumbers.includes(newNumber);
                        if (isHit) {
                            playSound();
                        } else {
                            const kenoSound = new Audio(kenosound);
                            const volume = mutedStates['keno'] ? 0 : parseFloat(volumes['keno'] || 0.5);
                            kenoSound.volume = Math.min(Math.max(volume, 0), 1);
                            kenoSound.play();
                        }
                        index++;
                    } else {
                        clearInterval(drawIntervalRef.current);
                        setGameOver(true);
                        setIsActive(false);
                        setMultiplier(data.multiplier);
                        setWinnings(data.winnings);
                        fetchBalances();
                        resolve(true);
                    }
                }, Math.max(50, 180 / speedRef.current));
            });

        } catch (error) {
            console.error('Error starting game:', error);
            toast.error("Error starting game");
            setIsActive(false);
            return false;
        }
    };

    const handleModeChange = (newMode) => {
        setMode(newMode);
        setSelectedNumbers([]);
        setAutoSelectedNumbers([]);
        setNumberOfGames(0);
        setGameOver(false);
    };

    const resetNumbers = () => {
        setAutoSelectedNumbers([]);
    };

    const pickRandomNumber = () => {
        const availableNumbers = Array.from({ length: 40 }, (_, i) => i + 1)
            .filter(num => !selectedNumbers.includes(num));

        if (availableNumbers.length === 0) return;

        const randomIndex = Math.floor(Math.random() * availableNumbers.length);
        const randomNumber = availableNumbers[randomIndex];

        setSelectedNumbers(prev => [...prev, randomNumber]);
    };

    const handleNumberClick = (number) => {
        if (isActive) return;
        if (drawnNumbers.length > 0) setDrawnNumbers([]);

        setSelectedNumbers(prev => {
            if (prev.includes(number)) {
                return prev.filter(num => num !== number);
            }
            if (prev.length >= 10) {
                toast.error("Maximum 10 numbers can be selected");
                setShake(true);
                setTimeout(() => setShake(false), 300);
                return prev;
            }
            return [...prev, number];
        });
    };

    const renderNumber = (number) => {
        const isSelected = selectedNumbers.includes(number);
        const isDrawn = drawnNumbers.includes(number);
        const isHit = isSelected && isDrawn;
        const isCurrentDraw = drawnNumbers.length > 0 &&
            drawnNumbers[drawnNumbers.length - 1] === number;

        return (
            <div
                key={number}
                className={`keno-number 
                    ${isSelected ? 'selected' : ''} 
                    ${isDrawn ? 'drawn' : ''} 
                    ${isHit ? 'hit' : ''}
                    ${isCurrentDraw ? 'current-draw' : ''}
                    ${shake ? 'shake-animation' : ''}`}
                onClick={() => handleNumberClick(number)}
            >
                {!isDrawn && <span className="number-text">{number}</span>}
                {isDrawn && !isHit && <ImCross className="brick-x" />}
                {isHit && <GiEmerald className="hit-gem" />}
            </div>
        );
    };

    const resetGrid = () => {
        setSelectedNumbers([]);
        setAutoSelectedNumbers([]);
    };

    const startAutoPlay = async () => {
        isActiveRef.current = true;
        setIsActive(true);
        let success = await startGame();
        if (!success) return;

        let gamesCount = 1;
        const intervalId = setInterval(async () => {
            if (gamesCount >= numberOfGames || !isActiveRef.current || bet > moneyRef.current) {
                clearInterval(intervalId);
                setAutoPlayIntervalId(null);
                setIsActive(false);
                isActiveRef.current = false;
                if (bet > moneyRef.current) {
                    toast.error("Insufficient balance");
                    bankPinSound.play();
                    setShake(true);
                    setTimeout(() => setShake(false), 300);
                }
                return;
            }

            success = await startGame();
            if (!success) {
                clearInterval(intervalId);
                setAutoPlayIntervalId(null);
                setIsActive(false);
                isActiveRef.current = false;
                return;
            }

            gamesCount++;
            setGamesPlayed(gamesCount);
        }, 2000 / speedRef.current + 1000);

        setAutoPlayIntervalId(intervalId);
    };

    const stopAutoPlay = () => {
        if (autoPlayIntervalId) {
            clearInterval(autoPlayIntervalId);
            setAutoPlayIntervalId(null);
            isActiveRef.current = false;
            setIsActive(false);
            setGameOver(true);
        }
    };

    // Clean up on unmount or navigation
    useEffect(() => {
        return () => {
            // Clear draw interval
            if (drawIntervalRef.current) {
                clearInterval(drawIntervalRef.current);
            }
            // Clear autoplay interval
            if (autoPlayIntervalId) {
                clearInterval(autoPlayIntervalId);
            }
            // Reset game state
            setIsActive(false);
            isActiveRef.current = false;
            setGameOver(true);
            setDrawnNumbers([]);
        };
    }, []);

    // Stop game when navigating away
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (autoPlayIntervalId) {
                clearInterval(autoPlayIntervalId);
            }
            if (drawIntervalRef.current) {
                clearInterval(drawIntervalRef.current);
            }
            isActiveRef.current = false;
            setIsActive(false);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [autoPlayIntervalId]);

    return (
        <div className="main-container items-center justify-center">
            <div className="flex md:h-[660px]">
                <KenoBettingWindow
                    signedIn={signedIn}
                    bet={bet}
                    handleAmountChange={handleBetChange}
                    handleBetBlur={handleBetBlur}
                    handleHalfBet={handleHalfBet}
                    handleDoubleBet={handleDoubleBet}
                    startGame={startGame}
                    gameStarted={isActive || isActiveRef.current}
                    multiplier={multiplier}
                    risk={risk}
                    handleRiskChange={(e) => {
                        setRisk(e.target.value);
                    }}
                    handleCashOut={() => { }} // To be implemented
                    selectedNumbersLength={selectedNumbers.length}
                    pickRandomNumber={pickRandomNumber}
                    disableModifications={isActive || isActiveRef.current}
                    onModeChange={handleModeChange}
                    resetGrid={resetGrid}
                    autoSelectedGridLength={selectedNumbers.length}
                    resetNumbers={resetNumbers}
                    numberOfGames={numberOfGames}
                    setNumberOfGames={(e) => setNumberOfGames(parseInt(e.target.value))}
                    startAutoPlay={startAutoPlay}
                    stopAutoPlay={stopAutoPlay}
                    recovering={recovering}
                    shake={shake}
                    balanceType={balanceType}
                    nextMultiplier={nextMultiplier}
                    autoCashoutAmount={autoCashoutAmount}
                    autoMultiplier={autoMultiplier}
                    speed={speed}
                    setSpeed={setSpeed}
                    mode={mode}
                    gameId="keno"
                />
            </div>

            <div className="keno-container">
                {gameOver && !isNaN(Number(winnings)) && Number(winnings) > 0 && (
                    <KenoWinningsComponent
                        multiplier={multiplier}
                        winnings={Number(winnings)}
                    />
                )}
                <div className="keno-grid">
                    <div className="keno-grid-inner">
                        {Array.from({ length: 40 }, (_, i) => renderNumber(i + 1))}
                    </div>

                </div>
                <KenoPayoutTable
                    selectedCount={selectedNumbers.length}
                    risk={risk.toLowerCase()}
                />
            </div>
        </div>
    );
};

export default Keno;

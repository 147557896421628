import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import GeneralBettingWindow from "../GeneralBettingWindow";
import EnhancedWinningsComponent from "../EnhancedWinningsComponent";
import withFadeInDelay from "../withFadeInDelay";
import { supabase, fetchAndUpdateBalance } from "../../api/supabaseClient.js";
import Slider from '@mui/material/Slider';
import "./Dice.css";
import Odds from "./Odds.jsx";
import RollArrow from "./RollArrow.jsx";
import thumbImage from "../../assets/zoom_slider_thumb.png";
import tickSound from "../../assets/tick.mp3";
import rollSound from "../../assets/dice_roll.mp3";
import { toast } from 'react-toastify';
import bankpinfail from '../../assets/bankpinfail.mp3';
import { useVolume } from '../../contexts/VolumeContext'; // Import useVolume hook
import { useBet } from '../../hooks/useBet'; // Import useBet hook
import BettingWindow from "../BettingWindow"; //Removing this line somehow breaks everything lol
import confetti from 'canvas-confetti';

const FadedInWinningsComponent = withFadeInDelay(EnhancedWinningsComponent, 50);

const calculateMultiplier = (value, isUnder) => {
  const houseEdge = 0.04;
  const p = isUnder ? value / 101 : (100 - value) / 101;
  const baseMultiplier = (1 - houseEdge) / p;
  return baseMultiplier.toFixed(4);
};

const Dice = () => {
  const {
    bet,
    setBet,
    handleBetChange: handleMainBetChange,
    handleBetBlur: handleMainBetBlur,
    handleHalfBet: handleMainHalfBet,
    handleDoubleBet: handleMainDoubleBet,
  } = useBet(0);

  const [value, setValue] = useState(50);
  const [multiplier, setMultiplier] = useState(calculateMultiplier(50, false));
  const [gameStarted, setGameStarted] = useState(false);
  const [roll, setRoll] = useState(100);
  const [winnings, setWinnings] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(0);

  const [mode, setMode] = useState('Manual');
  const [autoPlayIntervalId, setAutoPlayIntervalId] = useState(null);
  const [numberOfGames, setNumberOfGames] = useState(0);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const isActiveRef = useRef(false);

  const sliderRef = useRef(null);
  const tickAudio = useRef(new Audio(tickSound));
  const rollAudio = useRef(new Audio(rollSound));

  const { session, signedIn } = useAuth();
  const { MAXBET, balanceType, changeBalanceType, fetchBalances, getActiveBalance, setDisableModifications } = useBalance();
  const { volumes, mutedStates, setActiveGame } = useVolume(); // Use the useVolume hook to get volumes

  const [shake, setShake] = useState(false);
  const bankPinSound = new Audio(bankpinfail);

  const moneyRef = useRef(getActiveBalance());

  const [isUnder, setIsUnder] = useState(false);

  useEffect(() => {
    moneyRef.current = getActiveBalance();
  }, [getActiveBalance()]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setNumberOfGames(0);
  };
  const startGame = async () => {
    if (gameStarted || isActiveRef.current) {
      if (bet > moneyRef.current) {
        toast.error("Insufficient balance. Bet cannot be higher than balance.");
        bankPinSound.play();
        setGameStarted(false);
        isActiveRef.current = false;
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 200);
        return false;
      }
      const { data, error } = await supabase.rpc("start_dice_new", {
        v_user_id: session?.user.id,
        v_bet: bet,
        v_target: value,
        v_type: balanceType,
        v_variant: isUnder ? 'under' : 'over'
      });
      if (error) {
        toast.error(error.message);
        bankPinSound.play();
        setGameStarted(false);
        isActiveRef.current = false;
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 200);
        return false;
      }
      setRoll(data[0].roll);
      setWinnings(data[0].winnings);
      setGameStarted(false);
      await fetchBalances();
      setGameOver(true);
      confetti.reset();
      return true;
    }
  };
  const startAutoPlay = async () => {
    isActiveRef.current = true;
    setGameStarted(true);
    const success = await startGame();
    if (!success) return;
    let gamesCount = 1;
    const intervalId = setInterval(async () => {
      if (gamesCount >= numberOfGames || !isActiveRef.current || bet > moneyRef.current) {
        clearInterval(intervalId);
        setAutoPlayIntervalId(null);
        setGameStarted(false);
        setGameOver(true);
        isActiveRef.current = false;
        if (bet > moneyRef.current) {
          toast.error("Insufficient balance. Bet cannot be higher than balance.");
          bankPinSound.play();
          setShake(true);
          setTimeout(() => {
            setShake(false);
          }, 200);
        }
        return;
      }
      setGameStarted(true);
      setGameOver(false);
      await startGame();
      gamesCount++;
      setGamesPlayed(gamesCount);
    }, 1000); // Adjust interval as needed

    setAutoPlayIntervalId(intervalId);
  };
  const stopAutoPlay = () => {
    if (autoPlayIntervalId) {
      clearInterval(autoPlayIntervalId);
      setAutoPlayIntervalId(null);
      isActiveRef.current = false;
      setGameStarted(false);
      setGameOver(true);
    }
  };

  useEffect(() => {
    setMultiplier(calculateMultiplier(value, isUnder));
  }, [value, isUnder]);

  const handleSliderChange = (event, newValue) => {
    tickAudio.current.volume = mutedStates["dice"] ? 0 : volumes["dice"] || 1;
    tickAudio.current.play();

    if (isUnder) {
      // Under mode: clamp between 1-95
      const clampedValue = Math.max(1, Math.min(newValue, 95));
      setValue(clampedValue);
    } else {
      // Over mode: clamp between 5-99
      const clampedValue = Math.max(5, Math.min(newValue, 99));
      setValue(clampedValue);
    }
  };

  useEffect(() => {
    if (!isActiveRef.current) {
      setGameOver(false);
      startGame();
    }
  }, [gameStarted]);
  useEffect(() => {
    return () => {
      if (autoPlayIntervalId) {
        clearInterval(autoPlayIntervalId);
      }
    };
  }, [autoPlayIntervalId]); // Cleanup on component unmount

  useEffect(() => {
    if (sliderRef.current) {
      const slider = sliderRef.current.querySelector('.MuiSlider-root');
      if (!slider) return;
      
      const sliderRect = slider.getBoundingClientRect();
      const railWidth = sliderRect.width;
      
      // Calculate position as a percentage of the rail width, with a small offset adjustment
      const percentage = roll / 100;
      const newPosition = (percentage * railWidth) - 8; // Subtract a small offset to align correctly

      rollAudio.current.currentTime = 0;
      rollAudio.current.volume = mutedStates["dice"] ? 0 : volumes["dice"] || 1;
      rollAudio.current.play();
      setMarkerPosition(newPosition);
    }
  }, [roll]);

  useEffect(() => {
    setDisableModifications(isActiveRef.current);
  }, [isActiveRef.current]);
  useEffect(() => {
    setActiveGame('dice');
    return () => {
      setDisableModifications(false);
    }
  }, []);

  const marks = [
    { value: 0, label: '0' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 75, label: '75' },
    { value: 100, label: '100' },
  ];

  const toggleRollMode = () => {
    setIsUnder(!isUnder);
    // Only adjust value if it's outside the valid range for the new mode
    if (isUnder) {
      // Switching to Over mode
      if (value < 5) setValue(5);
    } else {
      // Switching to Under mode
      if (value > 95) setValue(95);
    }
  };

  return (
    <div className="main-container">
      <GeneralBettingWindow
        bets={[
          {
            name: "Amount",
            bet: bet,
            handleAmountChange: handleMainBetChange,
            handleBlur: handleMainBetBlur,
            handleHalfBet: handleMainHalfBet,
            handleDoubleBet: handleMainDoubleBet,
            valid: bet > 0,
            winnings: winnings,
          }]}
        startGame={() => setGameStarted(true)}
        signedIn={signedIn}
        gameStarted={isActiveRef.current}
        gameOver={gameOver}
        autoPlay={true}
        numberOfGames={numberOfGames}
        onModeChange={(newMode) => handleModeChange(newMode)}
        setNumberOfGames={(e) => { setNumberOfGames(parseInt(e.target.value)) }}
        startAutoPlay={startAutoPlay}
        stopAutoPlay={stopAutoPlay}
        shake={shake}
        balanceType={balanceType}
        gameId="dice"
        betDelay={200}
      />
      <div className="game-container border-2 bg-primary-500 flex items-center justify-center w-full relative sm:flex-col lg:flex-row">
        <div className="w-full flex flex-col items-center">
          <div className="roll-mode-toggle w-full max-w-[300px] sm:mb-4 lg:absolute lg:top-4 lg:left-1/2 lg:-translate-x-1/2 lg:mb-0">
            <button
              className={`sm:text-sm lg:text-base ${!isUnder ? 'active' : ''}`}
              onClick={() => !isActiveRef.current && toggleRollMode()}
              disabled={isActiveRef.current}
            >
              Roll Over
            </button>
            <button
              className={`sm:text-sm lg:text-base ${isUnder ? 'active' : ''}`}
              onClick={() => !isActiveRef.current && toggleRollMode()}
              disabled={isActiveRef.current}
            >
              Roll Under
            </button>
          </div>
          {gameOver && (
            <FadedInWinningsComponent
              winnings={winnings}
              push={false}
              gameOver={gameOver}
              shouldPlaySound={true}
              additionalStyles={'w-24 h-24'}
            />
          )}
          <div className="p-12 w-full mx-8 sm:mx-2">
            <div className="w-full space-y-4 md:space-y-12" ref={sliderRef}>
              <Slider
                value={value}
                min={0}
                max={100}
                step={1}
                onChange={handleSliderChange}
                marks={marks}
                valueLabelDisplay="on"
                track={false}
                disabled={isActiveRef.current}
                aria-labelledby="continuous-slider"
                sx={{
                  '& .MuiSlider-thumb': {
                    backgroundColor: 'transparent',
                    width: 36,
                    height: 36,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&::before': {
                      content: '""',
                      display: 'block',
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${thumbImage})`,
                      backgroundSize: 'cover',
                      borderRadius: 0, // Remove border-radius to avoid cutting off the image
                    },
                    '&:hover, &:focus, &:active': {
                      boxShadow: 'none', // Remove hover effect
                    },
                  },
                  '& .MuiSlider-rail': {
                    background: isUnder ?
                      `linear-gradient(to right, hsla(120, 100%, 25%, 1), hsla(120, 100%, 50%, 1) ${value}%, hsla(0, 100%, 50%, 1) ${value}%, hsla(0, 100%, 25%, 1))` :
                      `linear-gradient(to right, hsla(0, 100%, 25%, 1), hsla(0, 100%, 50%, 1) ${value}%, hsla(120, 100%, 50%, 1) ${value}%, hsla(120, 100%, 25%, 1))`,
                    width: '100%',
                    height: '28px',
                    opacity: 1,
                    borderRadius: 0,
                  },
                  '& .MuiSlider-mark': {
                    width: 2,
                    height: 24,
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                  '& .MuiSlider-markLabel': {
                    color: 'white',
                    fontSize: '2rem',
                    fontFamily: 'CustomFont, system-ui',
                  },
                  '& .MuiSlider-valueLabel': {
                    fontFamily: 'CustomFont, system-ui',
                    fontSize: '2rem',
                    '@media (max-width: 768px)': {
                      display: 'none',
                    },
                  },
                }}
              />
              <div
                className="marker"
                style={{
                  left: `${markerPosition}px`,
                  transition: 'left 1s ease-in-out',
                }}
              >
                <div className="marker-label">
                  {roll}
                </div>
              </div>
                <Odds value={value} multiplier={multiplier} isUnder={isUnder} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dice;
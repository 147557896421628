import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Paper, Grid, Dialog, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import PeopleIcon from '@mui/icons-material/People';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import './ReferFriend.css';
import { GoldCoin } from '../Coins';
import { useReferralStats } from '../../hooks/useReferralStats';
import LoadingScreen from '../LoadingScreen';
import { supabase } from '../../api/supabaseClient';

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-root': {
        color: '#e0e0e0',
        backgroundImage: 'radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%)',
        border: '2px solid #efbf04',
        borderRadius: '8px',
        fontSize: '1rem',
        fontFamily: 'monospace',
        height: '45px',
    },
    '& .MuiInputBase-input': {
        padding: '0.75rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#efbf04',
    color: '#000',
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: '0.05em',
    padding: '0.75rem',
    borderRadius: '4px',
    height: '45px',
    transition: 'all 0.3s ease',
    '&:hover': {
        backgroundColor: '#d4a803',
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 8px rgba(239, 191, 4, 0.2)',
    },
}));

const StyledTierBox = styled(Paper)(({ theme, active, tierLevel }) => ({
    backgroundColor: 'hsla(24, 12.82%, 22%, 100%)',
    padding: '1.5rem',
    borderRadius: '12px',
    height: '100%',
    border: active ? '2px solid hsla(120, 100%, 45%, 80%)' : 'none',
    transition: 'transform 0.2s ease',
    background: (() => {
        switch (tierLevel) {
            case 1: // Bronze
                return 'linear-gradient(145deg, hsla(30, 30%, 25%, 100%), hsla(30, 25%, 20%, 100%))';
            case 2: // Iron
                return 'linear-gradient(145deg, hsla(0, 0%, 35%, 100%), hsla(0, 0%, 25%, 100%))';
            case 3: // Silver
                return 'linear-gradient(145deg, hsla(0, 0%, 45%, 100%), hsla(0, 0%, 35%, 100%))';
            case 4: // Gold
                return 'linear-gradient(145deg, hsla(45, 50%, 35%, 100%), hsla(45, 40%, 25%, 100%))';
            case 5: // Platinum
                return 'linear-gradient(145deg, hsla(0, 0%, 80%, 100%), hsla(0, 0%, 70%, 100%), hsla(240, 5%, 60%, 100%))';
            case 6: // Diamond
                return 'linear-gradient(145deg, hsla(195, 50%, 35%, 100%), hsla(195, 40%, 25%, 100%))'; // Updated Diamond gradient
            default:
                return 'hsla(24, 12.82%, 22%, 100%)';
        }
    })(),
    '&:hover': {
        transform: 'translateY(-4px)',
    }
}));

const tiers = [
    {
        name: 'Bronze',
        deposits: 'Default',
        commission: '0.5%',
    },
    {
        name: 'Iron',
        deposits: '500',
        commission: '1%',
    },
    {
        name: 'Silver',
        deposits: '2,000',
        commission: '1.5%',
    },
    {
        name: 'Gold',
        deposits: '4000',
        commission: '2%',
    },
    {
        name: 'Platinum',
        deposits: '7,500',
        commission: '2.5%',
    },
    {
        name: 'Diamond',
        deposits: '10,000',
        commission: '3%',
    }
];

const ReferralHighlights = (props) => {
    const [openTiers, setOpenTiers] = useState(false);
    const stats = props.statsObject;
    const claimAvailableFunds = props.claimAvailableFunds;

    return (
        <Box className="highlights-container">
            {/* Available Funds Row */}
            <Box 
                className="funds-card" 
                onClick={() => stats.availableFunds > 0 ? claimAvailableFunds() : null}
                sx={{ 
                    cursor: stats.availableFunds === 0 ? 'not-allowed' : 'pointer',
                    pointerEvents: stats.availableFunds === 0 ? 'none' : 'auto'
                }}
            >
                <Box className="funds-content">
                    <Box className="funds-info">
                        <Typography className="funds-label">
                            <AccountBalanceWalletIcon sx={{ marginRight: '0.5rem' }} />
                            Available Funds
                        </Typography>
                        <Box className="ml-8 value-with-coin">
                            <GoldCoin style={{ width: '24px', height: '24px' }} />
                            {stats.availableFunds.toFixed(2)}
                        </Box>
                    </Box>
                </Box>
                <Box className="claim-hover-text">CLAIM</Box>
            </Box>

            {/* Stats Row */}
            <Box className="highlights-grid" sx={{ marginTop: '1rem' }}>
                <Box className="highlight-card">
                    <PeopleIcon className="highlight-icon" />
                    <Box className="highlight-content">
                        <Typography className="highlight-label">Unique Referrals</Typography>
                        <p className="font-bold text-xl select-none">{stats.allReferrals}</p>
                    </Box>
                </Box>

                {/* Commission Paid */}
                <Box className="highlight-card">
                    <PaymentsIcon className="highlight-icon" />
                    <Box className="highlight-content">
                        <Typography className="highlight-label">Commission Paid</Typography>
                        <Typography className="highlight-value">
                            <Box className="value-with-coin">
                                <GoldCoin style={{ width: '24px', height: '24px' }} />
                                {stats.commissionPaid.toFixed(2)}
                            </Box>
                        </Typography>
                    </Box>
                </Box>

                {/* Total Deposit */}
                <Box className="highlight-card">
                    <AccountBalanceWalletIcon className="highlight-icon" />
                    <Box className="highlight-content">
                        <Typography className="highlight-label">Total Deposited Under Referrals</Typography>
                        <Typography className="highlight-value">
                            <Box className="value-with-coin">
                                <GoldCoin style={{ width: '24px', height: '24px' }} />
                                {stats.totalDeposit.toFixed(2)}
                            </Box>
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box className="current-tier-section">
                <StyledTierBox
                    elevation={3}
                    onClick={() => setOpenTiers(true)}
                    active={true}
                    tierLevel={stats.tierLevel}
                    className="current-tier-box"
                >
                    <Box className="tier-header">
                        <Typography className="tier-name">{tiers.find(tier => tier.name === stats.currentTier)?.name}</Typography>
                        <Typography className="tier-deposits">
                            Referral deposits: {tiers.find(tier => tier.name === stats.currentTier)?.deposits === 'Default' ? 'Default' : `${tiers.find(tier => tier.name === stats.currentTier)?.deposits} tokens`}
                        </Typography>
                    </Box>

                    <Box className="tier-commission">
                        <Typography className="commission-rate">{tiers.find(tier => tier.name === stats.currentTier)?.commission}</Typography>
                        <Typography className="commission-label">Commission</Typography>
                    </Box>

                    {/* <Box className="tier-requirements">
            {tiers.find(tier => tier.name === stats.currentTier)?.requirements.map((requirement, idx) => (
              <Box key={idx} className="requirement-item">
                <CheckCircleIcon className="check-icon" />
                <Typography>{requirement}</Typography>
              </Box>
            ))}
          </Box> */}
                </StyledTierBox>
            </Box>

            <Dialog
                open={openTiers}
                onClose={() => setOpenTiers(false)}
                maxWidth="lg"
                fullWidth
                classes={{ paper: 'refer-dialog' }}
            >
                <Box className="refer-dialog-header">
                    <Typography className="refer-dialog-title">Affiliate Tiers</Typography>
                    <IconButton
                        onClick={() => setOpenTiers(false)}
                        className="refer-dialog-close"
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="refer-tiers-grid">
                    {tiers.map((tier, index) => (
                        <StyledTierBox
                            key={index}
                            elevation={3}
                            active={tier.name === stats.currentTier}
                            tierLevel={index + 1}
                        >
                            <Typography className="refer-tier-name">{tier.name}</Typography>
                            <Typography className="refer-tier-deposits">
                                Referral deposits: {tier.deposits === 'Default' ? 'Default' : `${tier.deposits} tokens`}
                            </Typography>

                            <Box className="refer-tier-commission">
                                <Typography className="refer-commission-rate">{tier.commission}</Typography>
                                <Typography className="refer-commission-label">Commission</Typography>
                            </Box>

                            {/* <Box className="refer-requirement">
                <CheckCircleIcon className="refer-check-icon" />
                <Typography className="refer-requirement-text">
                  5 minimum referrals to claim
                </Typography>
              </Box> */}
                        </StyledTierBox>
                    ))}
                </Box>
            </Dialog>
        </Box>
    );
};

export default function ReferFriend() {
    let stats = {
        currentTier: 'Bronze',
        tierLevel: 1,
        allReferrals: 1,
        commissionPaid: 0,
        totalDeposit: 0,
        availableFunds: 0
    };
    const { referralStats, isLoading,   refetch, error } = useReferralStats();
    if (referralStats) {
        stats = referralStats;
    }
    const { session } = useAuth();
    const [copied, setCopied] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);


    const referralLink = `https://sandcasino.io/?r=${session?.user?.user_metadata?.username || ''}`;

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(referralLink);
            setCopied(true);
            toast.success('Referral link copied to clipboard!');
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            toast.error('Failed to copy referral link');
        }
    };
    const claimAvailableFunds = async () => {
        if (stats.availableFunds === 0 || buttonDisabled) return;
        
        setButtonDisabled(true); // Disable the button immediately when clicked
        
        const { data, error } = await supabase.rpc('claim_commission', { v_player_id: session?.user?.id });
        if (error) {
            toast.error('Failed to claim available funds');
            console.error('Error claiming available funds:', error);
        } else {
            toast.success('Available funds claimed successfully');
            await refetch();
        }

        // Re-enable the button after 2 seconds
        setTimeout(() => {
            setButtonDisabled(false);
        }, 2000);
    }

    return (
        isLoading ? <LoadingScreen /> :
            <Paper className="refer-container" elevation={3}>
                <Typography className="refer-title">
                    Refer a Friend
                </Typography>
                <Typography className="refer-subtitle">
                    Share your referral link with friends and earn rewards!
                </Typography>
                <Box className="refer-link-container">
                    <StyledTextField
                        fullWidth
                        value={referralLink}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledButton
                        variant="contained"
                        startIcon={<ContentCopyIcon />}
                        onClick={handleCopy}
                        fullWidth
                    >
                        {copied ? 'Copied!' : 'Copy Referral Link'}
                    </StyledButton>
                </Box>

                <ReferralHighlights statsObject={stats} claimAvailableFunds={claimAvailableFunds} />
            </Paper>
    );
}

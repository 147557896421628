import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, Grid, Divider, Container } from '@mui/material';
import LevelUpBar from '../LevelUpBar.tsx';
import { useProfile } from '../../contexts/ProfileContext.tsx';
import { GoldCoin } from '../Coins.tsx';
import { supabase } from "../../api/supabaseClient.js";
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext.js';
import { useBalance } from '../../contexts/BalanceContext.js';
import DailyChallenge from './DailyChallenge';
import LoadingScreen from '../LoadingScreen';
import styled from '@emotion/styled';

interface Reward {
    reward_key: string;
    reward_claimed: boolean;
}

interface Challenge {
    id: number;
    description: string;
    minimum_bet: number;
    reward: number;
    expiry: string;
    completed: boolean;
    completed_by: string | null;
}

const rewardMap: { [key: string]: { description: string, value: number | string } } = {
    "early_adopter": { description: "Permanent 2.5% increased rakeback", value: 10 },
    "discord_verified": { description: "Permanent 2.5% increased rakeback", value: 0 },
    "0": { description: "5% Rakeback", value: "5%" },
    "1": { description: "7.5% Rakeback", value: "7.5%" },
    "2": { description: "10% Rakeback", value: "10%" },
    "3": { description: "12.5% Rakeback", value: "12.5%" },
    "4": { description: "15% Rakeback", value: "15%" },
    "5": { description: "17.5% Rakeback", value: "17.5%" },
    "6": { description: "20% Rakeback", value: "20%" },
    "7": { description: "22.5% Rakeback", value: "22.5%" },
    "8": { description: "25% Rakeback", value: "25%" },
    "9": { description: "27.5% Rakeback", value: "27.5%" },
    "10": { description: "30% Rakeback", value: "30%" },
};

const goldTokenMap: { [key: string]: number } = {
    "1": 2.5, "2": 10, "3": 25, "4": 50, "5": 100,
    "6": 175, "7": 275, "8": 375, "9": 500, "10": 1000
};

const mockChallenge: Challenge = {
    id: 1,
    description: "No Active Challenge",
    minimum_bet: 0,
    reward: 0,
    expiry: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
    completed: true,
    completed_by: null
};

const RewardsContainer = styled(Container)({
    padding: '24px 12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    maxWidth: '800px !important',
});

const RewardCard = styled(Paper)(({ claimed }: { claimed?: boolean }) => ({
    backgroundImage: 'radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%)',
    borderRadius: '12px',
    padding: '16px',
    border: '2px solid #efbf04',
    transition: 'all 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 8px 16px rgba(239, 191, 4, 0.2)',
    },
    ...(claimed && {
        opacity: 0.7,
        '&::after': {
            content: '"CLAIMED"',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#efbf04',
            padding: '8px 32px',
            border: '2px solid #efbf04',
            borderRadius: '4px',
            pointerEvents: 'none',
        }
    })
}));

const RewardSection = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

const SectionTitle = styled(Typography)({
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#e0e0e0',
    textAlign: 'center',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '-8px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '60px',
        height: '2px',
        background: 'linear-gradient(90deg, transparent, #efbf04, transparent)',
    }
});

export default function Rewards() {
    const { rewards, currentRakeback, setCurrentRakeback, updateRewardsAndRakeback, xp, level } = useProfile();
    const { session } = useAuth();
    const { fetchBalances } = useBalance();
    const [challenges, setChallenges] = useState<Challenge[]>([]);
    const [challenge, setChallenge] = useState<Challenge>(mockChallenge);
    const [isLoading, setIsLoading] = useState(true);
    const [isClaimingRakeback, setIsClaimingRakeback] = useState(false);
    const [claimingRewards, setClaimingRewards] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                updateRewardsAndRakeback(),
                fetchActiveChallenge()
            ]);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const fetchActiveChallenge = async () => {
        try {
            const { data, error } = await supabase
                .from('challenges')
                .select('*')
                .gt('expiry', new Date().toISOString())
                .order('expiry', { ascending: true }) // Get the soonest expiry first
                .limit(1)
                .single(); // Fetch one challenge

            if (error) {
                throw error;
            }

            if (data) {
                setChallenge(data);
            } else {
                //toast.info("No active challenges available.");
            }
        } catch (error) {
            console.error("Error fetching active challenge:", error);
            //toast.error("Failed to load the active challenge.");
        }
    };

    const handleClaim = async (rewardKey: string) => {
        if (claimingRewards[rewardKey]) return;
        
        setClaimingRewards(prev => ({ ...prev, [rewardKey]: true }));
        
        try {
            const { data, error } = await supabase.rpc("claim_reward", { 
                v_player_id: session?.user?.id, 
                v_reward_key: rewardKey 
            });
            
            if (error) {
                console.error("Error claiming reward:", error);
            } else {
                console.log("Reward claimed successfully:", data);
                toast.success("Reward claimed successfully");
                await fetchBalances();
            }

            // After successful claim, update the rewards
            await updateRewardsAndRakeback();
        } finally {
            setTimeout(() => {
                setClaimingRewards(prev => ({ ...prev, [rewardKey]: false }));
            }, 1000);
        }
    };

    const handleClaimRakeback = async () => {
        setIsClaimingRakeback(true);
        try {
            const { data, error } = await supabase.rpc("claim_rakeback", { v_player_id: session?.user?.id });
            if (error) {
                console.error("Error claiming rakeback:", error);
            } else {
                console.log("Rakeback claimed successfully:", data);
                toast.success("Rakeback claimed successfully");
                setCurrentRakeback(0);
            }
            // After successful claim, update the rakeback
            await updateRewardsAndRakeback();
            await fetchBalances();
        } finally {
            setTimeout(() => setIsClaimingRakeback(false), 1000);
        }
    };

    const getRewardLabel = (rewardKey: string): string => {
        if (rewardKey === 'early_adopter') return 'Early Adopter Reward';
        if (rewardKey === 'discord_verified') return 'Discord Reward';
        return `Level ${rewardKey} Reward`;
    };

    const renderRewards = () => {
        return rewards.map((reward) => {
            const rewardKey = reward.reward_key.toLowerCase();
            const rewardInfo = rewardMap[rewardKey] || { description: "Unknown reward", value: "N/A" };
            const goldTokens = goldTokenMap[rewardKey] || 0;

            return (
                <RewardCard key={rewardKey} claimed={reward.reward_claimed}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                        <Typography 
                            variant="subtitle2" 
                            align="center"
                            sx={{ 
                                color: '#efbf04',
                                opacity: 0.9,
                                textTransform: 'uppercase',
                                letterSpacing: '0.5px',
                                fontSize: '1.3rem',
                                fontWeight: 'bold',
                                marginBottom: '4px'
                            }}>
                            {getRewardLabel(rewardKey)}
                        </Typography>
                        
                        <Typography variant="h6" align="center" 
                            sx={{ 
                                color: '#efbf04',
                                fontWeight: 'bold',
                                textShadow: '0 0 10px rgba(239, 191, 4, 0.3)'
                            }}>
                            {rewardInfo.description}
                        </Typography>
                        
                        {(goldTokens > 0 || rewardKey === 'early_adopter') && (
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                gap: 1
                            }}>
                                <GoldCoin style={{ width: '24px', height: '24px' }} />
                                <Typography 
                                    variant="h5" 
                                    sx={{ 
                                        color: '#05f783',
                                        fontWeight: 'bold',
                                        textShadow: '0 0 10px rgba(5, 247, 131, 0.3)'
                                    }}>
                                    {goldTokens || rewardInfo.value}
                                </Typography>
                            </Box>
                        )}

                        <Button
                            variant="contained"
                            disabled={reward.reward_claimed || claimingRewards[rewardKey]}
                            onClick={() => handleClaim(rewardKey)}
                            sx={{
                                background: 'linear-gradient(45deg, #efbf04 30%, #ffd700 90%)',
                                color: '#000',
                                fontWeight: 'bold',
                                padding: '8px 16px',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #ffd700 30%, #efbf04 90%)',
                                },
                                '&:disabled': {
                                    background: 'hsla(24, 12.82%, 20%, 100%)',
                                    color: 'hsla(24, 12.82%, 40%, 100%)',
                                },
                                fontSize: '0.9rem',
                            }}
                        >
                            {reward.reward_claimed ? "Claimed" : "Claim Reward"}
                        </Button>
                    </Box>
                </RewardCard>
            );
        });
    };

    const renderNextLevelReward = () => {
        const nextLevel = level + 1;
        const nextReward = rewardMap[nextLevel.toString()];
        const nextGoldTokens = goldTokenMap[nextLevel.toString()] || 0;

        return (
            <RewardCard>
                <Typography variant="h5" align="center" 
                    sx={{ 
                        color: '#efbf04',
                        fontWeight: 'bold',
                        textShadow: '0 0 10px rgba(239, 191, 4, 0.3)'
                    }}>
                    Next Level Reward
                </Typography>
                <Typography variant="body1" align="center" sx={{ fontSize: '1.2rem', color: '#ccc', mb: 1 }}>
                    {nextReward?.description}
                </Typography>
                {level < 10 ? (
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        gap: 1
                    }}>
                        <GoldCoin style={{ width: '32px', height: '32px' }} />
                        <Typography 
                            variant="h4" 
                            sx={{ 
                                color: '#05f783',
                                fontWeight: 'bold',
                                textShadow: '0 0 10px rgba(5, 247, 131, 0.3)'
                            }}>
                            {nextGoldTokens}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                        <Typography variant="body1" align="center" sx={{ fontSize: '1.4rem', color: '#ccc', mb: 1 }}>
                            You've reached the maximum level!
                        </Typography>
                    </Box>
                )}
            </RewardCard>
        );
    };

    return (
        <RewardsContainer maxWidth="lg">
            <DailyChallenge challenge={challenge} />
            
            <RewardSection>
                <SectionTitle>Level Progress</SectionTitle>
                <LevelUpBar />
                {renderNextLevelReward()}
            </RewardSection>

            <RewardSection>
                <SectionTitle>Available Rewards</SectionTitle>
                <Grid container spacing={3}>
                    {rewards.length === 0 ? (
                        <Grid item xs={12}>
                            <RewardCard>
                                <Typography variant="h6" align="center" sx={{ color: '#ccc' }}>
                                    No rewards available to claim at this time.
                                </Typography>
                            </RewardCard>
                        </Grid>
                    ) : (
                        rewards.map((reward, index) => (
                            <Grid item xs={12} md={6} lg={4} key={index}>
                                {renderRewards()[index]}
                            </Grid>
                        ))
                    )}
                </Grid>
            </RewardSection>

            <RewardSection>
                <SectionTitle>Rakeback</SectionTitle>
                <RewardCard>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        gap: 2
                    }}>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1
                        }}>
                            <GoldCoin style={{ width: '32px', height: '32px' }} />
                            <Typography 
                                variant="h4" 
                                sx={{ 
                                    color: '#05f783',
                                    fontWeight: 'bold',
                                    textShadow: '0 0 10px rgba(5, 247, 131, 0.3)'
                                }}>
                                {currentRakeback.toFixed(2)}
                            </Typography>
                        </Box>

                        <Button
                            variant="contained"
                            disabled={currentRakeback <= 0 || isClaimingRakeback}
                            onClick={handleClaimRakeback}
                            sx={{
                                background: 'linear-gradient(45deg, #efbf04 30%, #ffd700 90%)',
                                color: '#000',
                                fontWeight: 'bold',
                                padding: '8px 24px',
                                fontSize: '1rem',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #ffd700 30%, #efbf04 90%)',
                                },
                                '&:disabled': {
                                    background: 'hsla(24, 12.82%, 20%, 100%)',
                                    color: 'hsla(24, 12.82%, 40%, 100%)',
                                }
                            }}
                        >
                            Claim Rakeback
                        </Button>
                    </Box>
                </RewardCard>
            </RewardSection>
        </RewardsContainer>
    );
}

import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import { supabase } from "../../api/supabaseClient.js";
import PalmBettingWindow from "./PalmBettingWindow";
import withFadeInDelay from "../withFadeInDelay";
import PalmTowerWinningsComponent from "./PalmWinningsComponent";
import { useVolume } from "../../contexts/VolumeContext";
import { useBet } from '../../hooks/useBet';
import './PalmTower.css';
import { GiAxeInLog } from "react-icons/gi";
import Coconut from '../../assets/coconut.webp';
import TowerBackground from '../../assets/towerbg.webp';
import { toast } from 'react-toastify';
import PalmSound from '../../assets/palmfound.mp3';
import ChopSound from '../../assets/treefall.mp3';
import bankpinfail from '../../assets/bankpinfail.mp3';
const FadedInWinningsComponent = withFadeInDelay(PalmTowerWinningsComponent, 100);

const PalmTower = () => {
    const { session, signedIn } = useAuth();
    const { balanceType, fetchBalances, setDisableModifications, changeBalanceType, getActiveBalance } = useBalance();
    const { setActiveGame, mutedStates, volumes } = useVolume();
    const palmSound = new Audio(PalmSound);
    const chopSound = new Audio(ChopSound);
    const bankPinSound = new Audio(bankpinfail);

    // Basic game state
    const [gameId, setGameId] = useState(null);
    const [gameStarted, setGameStarted] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [winnings, setWinnings] = useState(0);
    const [result, setResult] = useState('');
    const [difficulty, setDifficulty] = useState('Easy');
    const [shake, setShake] = useState(false);

    // Main bet handling
    const {
        bet,
        setBet,
        handleBetChange,
        handleBetBlur,
        handleHalfBet,
        handleDoubleBet,
    } = useBet(0);

    // Game configuration state
    const [grid, setGrid] = useState([]);
    const [revealed, setRevealed] = useState([]);
    const [currentRow, setCurrentRow] = useState(0); // Start from bottom row (0)
    const [multiplier, setMultiplier] = useState(1);
    const [nextMultiplier, setNextMultiplier] = useState(null);
    const [canCashOut, setCanCashOut] = useState(false);
    const [autoSelectedTiles, setAutoSelectedTiles] = useState([]);
    const [mode, setMode] = useState('Manual');
    const [numberOfGames, setNumberOfGames] = useState(0);
    const [autoPlayIntervalId, setAutoPlayIntervalId] = useState(null);
    const [speed, setSpeed] = useState(1);
    const [autoCashoutAmount, setAutoCashoutAmount] = useState(0.0);
    const [autoMultiplier, setAutoMultiplier] = useState(1.0);
    const [isActive, setIsActive] = useState(false);
    const isActiveRef = useRef(false);
    const speedRef = useRef(speed);
    useEffect(() => {
        speedRef.current = speed;
    }, [speed])

    const moneyRef = useRef(getActiveBalance());
    useEffect(() => {
        moneyRef.current = getActiveBalance();
    }, [getActiveBalance()]);

    // Difficulty settings with grid configurations
    const difficultySettings = {
        'Easy': { cols: 4 },
        'Medium': { cols: 3 },
        'Hard': { cols: 2 },
        'Expert': { cols: 3 },
        'Master': { cols: 4 }
    };
    const oddsMap = {
        'Easy': 0.75,
        'Medium': 0.6666666666667,
        'Hard': .5,
        'Expert': 0.3333333333333,
        'Master': .25
    }

    const startGame = async () => {
        if (bet <= 0 || !signedIn) return;

        try {
            setDisableModifications(true);
            const { data, error } = await supabase.rpc("start_palmtower", {
                v_player_id: session?.user.id,
                v_bet: bet,
                v_type: balanceType,
                v_risk: difficulty
            });

            if (error) {
                toast.error(error.message);
                return;
            }

            setIsActive(true);
            setGameOver(false);
            setCurrentRow(0);
            setMultiplier(1);
            setNextMultiplier(null);
            setCanCashOut(false);
            setWinnings(0);
            setRevealed([]);

            // Initialize the grid
            const cols = difficultySettings[difficulty].cols;
            const TOTAL_ROWS = 8;
            setGrid(Array(TOTAL_ROWS * cols).fill(null));

            // Store the game ID for future tile selections
            setGameId(data.id);

            await fetchBalances();
        } catch (err) {
            console.error(err);
            toast.error("Failed to start game");
        }
    };


    const handleCellClick = async (index) => {
        if (mode === 'Auto' && !isActive) {
            const cols = difficultySettings[difficulty].cols;
            const clickedRow = Math.floor(index / cols);


            // Calculate which row should be selectable (starting from bottom)
            const bottomRow = 0;  // Row 0 is the bottom row
            const expectedRow = bottomRow + autoSelectedTiles.length;

            // If clicking an already selected tile, allow deselection
            if (autoSelectedTiles.includes(index) && clickedRow === expectedRow - 1) {
                setAutoSelectedTiles(prev => prev.filter(i => i !== index));
                return;
            }

            // Only allow selection on the current expected row
            if (clickedRow !== expectedRow) {
                bankPinSound.play();
                toast.error('Please select tiles from bottom to top');  
                setShake(true);
                setTimeout(() => setShake(false), 300);
                return;
            }

            // Check if a tile from this row is already selected
            const existingTileInRow = autoSelectedTiles.find(tileIndex =>
                Math.floor(tileIndex / cols) === clickedRow
            );

            if (existingTileInRow) {
                toast.error('Only one tile can be selected per row');
                bankPinSound.play();
                setShake(true);
                setTimeout(() => setShake(false), 300);
                return;
            }

            setAutoSelectedTiles(prev => [...prev, index]);
            return;
        }
        if (!isActive || gameOver) return;
        const cols = difficultySettings[difficulty].cols;
        const rowStart = currentRow * cols;
        const rowEnd = rowStart + cols;
        if (index < rowStart || index >= rowEnd) return;

        try {
            const { data, error } = await supabase.rpc("palmtower_select_tile", {
                v_game_id: gameId,
                v_player_id: session?.user.id,
                v_index: index
            });

            if (error) {
                //toast.error(error.message);
                return;
            }

            // Update grid with the entire row
            const newGrid = [...grid];
            data.row.forEach(cell => {
                const idx = cell.index;
                newGrid[idx] = cell.type === 'safe' ? true : false;
            });
            setGrid(newGrid);

            // Add only the clicked cell to revealed
            const clickedCell = data.row.find(cell => cell.index === index);
            setRevealed([...revealed, clickedCell]);

            if (data.is_safe) {
                setMultiplier(data.multiplier);
                setNextMultiplier(data.next_multiplier);
                setCurrentRow(currentRow + 1);
                setCanCashOut(true);
                palmSound.volume = mutedStates['palmtower'] ? 0 : volumes['palmtower'];
                palmSound.play();
                if (data.game_over) {
                    handleWin(bet * data.multiplier);
                }
            } else {
                // Handle loss
                if (data.grid) {
                    // Update grid with the full grid
                    const fullGrid = data.grid;
                    const updatedGrid = [...grid];
                    fullGrid.forEach(cell => {
                        const idx = cell.index;
                        updatedGrid[idx] = cell.type === 'safe' ? true : false;
                    });
                    setGrid(updatedGrid);
                }
                chopSound.volume = mutedStates['palmtower'] ? 0 : volumes['palmtower'];
                chopSound.play();

                setGameOver(true);
                setIsActive(false);
                setResult('loss');
                await fetchBalances();
            }
        } catch (err) {
            console.error(err);
            toast.error("Failed to select tile");
        }
    };

    const renderTowerGrid = () => {
        const TOTAL_ROWS = 8;
        const cols = difficultySettings[difficulty].cols;
        const cells = [];

        const revealedIndices = new Set(revealed.map(cell => cell.index));

        for (let row = 0; row < TOTAL_ROWS; row++) {
            for (let col = 0; col < cols; col++) {
                const index = row * cols + col;
                const isCurrentRow = row === currentRow;
                const isRevealed = grid[index] !== null && grid[index] !== undefined;
                const isClickedCell = revealedIndices.has(index);
                const isSafe = grid[index];
                const isAutoSelected = mode === 'Auto' && autoSelectedTiles.includes(index);

                cells.push(
                    <div
                        key={index}
                        className={`palm__tower_cell
                            ${isCurrentRow && (isActive && !gameOver && mode === 'Manual') ? 'current' : ''}
                            ${isAutoSelected ? 'auto-selected' : ''}
                            ${isRevealed && isSafe && !isAutoSelected ? 'gem revealed' : ''}
                            ${isRevealed && isSafe === false && !isAutoSelected ? 'mine revealed' : ''}
                            ${isClickedCell && isSafe && !isAutoSelected ? 'gem selected' : ''}
                            ${isClickedCell && isSafe === false && !isAutoSelected ? 'mine axe-selected' : ''}
                            ${mode === "Auto" ? 'auto-selectable' : ''}
                            ${shake ? 'shake-animation' : ''}
                        `}
                        onClick={() => !isRevealed && handleCellClick(index)}
                    >
                        {isRevealed && (
                            isSafe ?
                                <img src={Coconut} alt="Coconut" className="palm__tower_icon palm__tower_coconut select-none" /> :
                                <GiAxeInLog className="palm__tower_icon palm__tower_axe select-none" />
                        )}
                    </div>
                );
            }
        }

        return cells.reverse();
    };

    const handleWin = async (amount) => {
        setWinnings(amount);
        setResult('win');
        setGameOver(true);
        setIsActive(false);
        await fetchBalances();
    };

    const handleCashOut = async () => {
        if (canCashOut) {
            try {
                const { data, error } = await supabase.rpc("palm_cash_out", {
                    v_game_id: gameId,
                    v_player_id: session?.user.id,
                });

                if (error) {
                    toast.error(error.message);
                    return;
                }

                handleWin(data.winnings);
                if (data.grid) {
                    const fullGrid = data.grid;
                    const updatedGrid = [...grid];
                    fullGrid.forEach(cell => {
                        const idx = cell.index;
                        updatedGrid[idx] = cell.type === 'safe' ? true : false;
                    });
                    setGrid(updatedGrid);
                }
            } catch (err) {
                console.error(err);
                toast.error("Failed to cash out");
            }
        }
    };
    const pickRandomUnrevealedTile = () => {
        const cols = difficultySettings[difficulty].cols;
        const rowStart = currentRow * cols;
        const rowEnd = rowStart + cols;
        let index = Math.floor(Math.random() * (rowEnd - rowStart)) + rowStart;
        handleCellClick(index);
    }
    const handleModeChange = (newMode) => {
        setMode(newMode);
        setGrid([]);
        setRevealed([]);
        setAutoSelectedTiles([]);
        setNumberOfGames(0);
        setGameOver(false);
    };
    useEffect(() => {
        const fetchActiveGame = async () => {
            try {
                const { data, error } = await supabase.rpc('palmtower_fetch_active', {
                    v_player_id: session?.user.id,
                });

                if (error) {
                    console.error('Error fetching active game:', error);
                    return;
                }
                if (data && data.f1) {
                    // There's an active game; recover the state
                    setGameId(data.f1);
                    setIsActive(true);
                    setGameOver(false);
                    setWinnings(0);
                    setResult('');
                    setMultiplier(data.f5);
                    setNextMultiplier(Math.round(100 * (.96 / (data.f6 * oddsMap[data.f9]))) / 100); // You may need to recalculate this based on the game state
                    setCanCashOut(true);
                    setDisableModifications(true);

                    // Set bet and balance type
                    setBet(data.f3);
                    changeBalanceType(data.f2);

                    // Set difficulty and columns
                    setDifficulty(data.f9);

                    // Initialize the grid based on the number of columns
                    const cols = data.f10;
                    const TOTAL_ROWS = 8;
                    setGrid(Array(TOTAL_ROWS * cols).fill(null));

                    // Set revealed cells
                    setRevealed(data.f4);

                    // Determine the current row based on the number of revealed moves
                    setCurrentRow(data.f4.length);

                    // Update the grid with revealed rows
                    const newGrid = [...Array(TOTAL_ROWS * cols).fill(null)];
                    data.f11.forEach(cell => {
                        const idx = cell.index;
                        newGrid[idx] = cell.type === 'safe' ? true : false;
                    });
                    setGrid(newGrid);

                    // Optionally, you can set the next multiplier based on your game's logic
                } else {
                    console.log('No active game found.');
                }
            } catch (err) {
                console.error('Failed to fetch active game:', err);
            }
        };

        if (session?.user.id) {
            fetchActiveGame();
        }
    }, [session?.user.id]);
    useEffect(() => {
        setDisableModifications(isActive);
    }, [isActive])
    useEffect(() => {
        setActiveGame('palmtower');
        return () => {
            setDisableModifications(false);
        }
    }, [])

    const instantPlay = async () => {
        isActiveRef.current = true;
        setIsActive(true);
        let gamesPlayed = 0;

        const intervalId = setInterval(async () => {
            if (gamesPlayed >= numberOfGames || !isActiveRef.current || bet > moneyRef.current) {
                clearInterval(intervalId);
                isActiveRef.current = false;
                setIsActive(false);
                setGrid([]);
                setRevealed([]);
                setGameOver(true);
                if (bet > moneyRef.current) {
                    toast.error("Insufficient balance. Bet cannot be higher than balance.");
                    bankPinSound.play();
                    setShake(true);
                    setTimeout(() => {
                        setShake(false);
                    }, 200);
                }
                return;
            }
            setGameOver(false);
            setGrid([]);
            setWinnings(0.0);
            try {
                const { data, error } = await supabase.rpc("palms_instant_play", {
                    v_player_id: session?.user.id,
                    v_bet: bet,
                    v_type: balanceType,
                    v_risk: difficulty,
                    preselected_grid: autoSelectedTiles
                });

                if (error) {
                    console.error("Error instant playing:", error);
                    toast.error(error.message);
                    return;
                }

                // Update game state
                setRevealed(data.revealed);
                setMultiplier(data.multiplier);
                setWinnings(data.winnings);

                // Show full grid if game was lost
                const fullGrid = data.grid;
                const updatedGrid = Array(8 * difficultySettings[difficulty].cols).fill(null);
                fullGrid.forEach(cell => {
                    const idx = cell.index;
                    updatedGrid[idx] = cell.type === 'safe' ? true : false;
                });
                setGrid(updatedGrid);
                if (data.winnings > 0) {
                    palmSound.volume = mutedStates['palmtower'] ? 0 : volumes['palmtower'];
                    palmSound.play();
                } else {
                    chopSound.volume = mutedStates['palmtower'] ? 0 : volumes['palmtower'];
                    chopSound.play()
                }

                await fetchBalances();
                setGameOver(true);
                gamesPlayed++;

            } catch (error) {
                console.error('Error in instant play:', error);
                toast.error("Error in instant play");
            }
        }, 2000 / speedRef.current);

        setAutoPlayIntervalId(intervalId);
    };

    const stopAutoBet = () => {
        if (autoPlayIntervalId) {
            clearInterval(autoPlayIntervalId);
            isActiveRef.current = false;
            setIsActive(false);
            setGameOver(true);
            setGrid([]);
        }
    };

    useEffect(() => {
        return () => {
            if (autoPlayIntervalId) {
                clearInterval(autoPlayIntervalId);
            }
        };
    }, [autoPlayIntervalId]);

    useEffect(() => {
        if (autoSelectedTiles.length === 0) {
            setAutoMultiplier(1.0);
            setAutoCashoutAmount(bet);
            return;
        }

        let runningProb = 1.0;
        const cols = difficultySettings[difficulty].cols;
        const probability = oddsMap[difficulty]; // Get probability based on difficulty

        // Calculate running probability for each selected tile
        for (let i = 0; i < autoSelectedTiles.length; i++) {
            runningProb *= probability;
        }

        // Calculate multiplier with house edge (0.96)
        const newMultiplier = 0.96 / runningProb;
        
        // Update states with formatted values
        setAutoMultiplier(Number(newMultiplier.toFixed(2)));
        setAutoCashoutAmount(Number((bet * newMultiplier).toFixed(2)));

    }, [autoSelectedTiles, bet, difficulty]);

    return (
        <div className="main-container items-center justify-center">
            <div className="flex md:h-[770px]">
                <PalmBettingWindow
                    key='palmtower'
                    signedIn={signedIn}
                    bet={bet}
                    handleAmountChange={handleBetChange}
                    handleBetBlur={handleBetBlur}
                    handleHalfBet={handleHalfBet}
                    handleDoubleBet={handleDoubleBet}
                    gameStarted={isActive}
                    startGame={startGame}
                    gameOver={gameOver}
                    balanceType={balanceType}
                    difficulty={difficulty}
                    handleDifficultyChange={(e) => {
                        setDifficulty(e.target.value);
                        setGrid([]);
                        setAutoSelectedTiles([]);
                    }}
                    gameId="palmtower"
                    multiplier={multiplier}
                    nextMultiplier={nextMultiplier}
                    handleCashOut={handleCashOut}
                    pickRandomTile={() => pickRandomUnrevealedTile()}
                    onModeChange={handleModeChange}
                    autoSelectedTiles={autoSelectedTiles}
                    isActive={isActive}
                    numberOfGames={numberOfGames}
                    setNumberOfGames={(e) => { setNumberOfGames(parseInt(e.target.value)) }}
                    autoSelectedGridLength={autoSelectedTiles.length}
                    resetGrid={() => { setAutoSelectedTiles([]); setGrid([]); }}
                    startAutoPlay={instantPlay}
                    stopAutoPlay={stopAutoBet}
                    speed={speed}
                    setSpeed={setSpeed}
                    disableModifications={isActive}
                    autoCashoutAmount={autoCashoutAmount}
                    autoMultiplier={autoMultiplier}
                />
            </div>
            <div className="game-container flex items-center justify-center w-[900px] h-[770px] relative" style={{ backgroundImage: `url(${TowerBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="palm">
                    <div className="palm__game_container">
                        <div className="palm__game_board">
                            {mode === 'Auto' && !isActive && autoSelectedTiles.length === 0 && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center pointer-events-none">
                                    <p className="pulsing-text text-xl md:text-3xl text-yellow-400 font-bold">
                                        Select tiles for auto-play
                                    </p>
                                </div>
                            )}
                            <div className={`palm__tower_grid cols-${difficultySettings[difficulty].cols}`}>
                                {renderTowerGrid()}
                            </div>
                            {gameOver && !isNaN(Number(winnings)) && Number(winnings) > 0 && (
                                <FadedInWinningsComponent
                                    multiplier={multiplier}
                                    winnings={Number(winnings)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PalmTower;

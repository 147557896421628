// Import necessary modules and components
import React, { useEffect, useState,useRef } from "react";
import { Card } from "../objects/card";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import CardComponent from "./Blackjack-component/CardComponent";
import TexasCardComponent from "./Ultimate-Texas/TexasCardComponent";
import "./GameBoard.css";
import { useVolume } from "../contexts/VolumeContext"; 


// Load audio file for card flip sound

// Define CardDeal component
export default function CardDeal({
  cards,
  name,
  animationStyle,
  onDealerFinishing,
  cardImages,
  speed,
}: {
  cards: Card[];
  name?: string;
  animationStyle?: string[];
  onDealerFinishing?: () => void;
  cardImages: any;
  speed: number;
}) {
  // Initialize state variables
  const [displayedCards, setDisplayedCards] = useState<number>(0);
  const [communityDisplay, setCommunityDisplay] = useState<number>(0);
  const [cardAnimations, setCardAnimations] = useState<string[]>([]);
  let audio = useRef( new Audio("/flipcard2.mp3"));
  const { mutedStates, volumes } = useVolume();

  // Set initial card animations to "texas-slide"
  useEffect(() => {
    setCardAnimations(new Array(5).fill("texas-slide"));
  }, []);

  // Update card animations if animationStyle prop is provided
  useEffect(() => {
    if (animationStyle) {
      setCardAnimations(animationStyle);
    }
  }, [animationStyle]);

  // Call onDealerFinishing function when two cards are displayed
  useEffect(() => {
    if (displayedCards === 2 && onDealerFinishing) {
      setTimeout(() => {
        onDealerFinishing();
      }, 1000/speed);
    }
  }, [displayedCards]);

  // Handle card dealing animation and sound
  useEffect(() => {
    if (name === "community") {
      setTimeout(() => {
        audio.current.volume = mutedStates['ultimatetexas'] ? 0 : volumes['ultimatetexas'];
        audio.current.play();
        setDisplayedCards(5);
      }, 100);
    } else {
      for (let idx = displayedCards; idx < cards.length; idx++) {
        setTimeout(() => {
          setDisplayedCards(idx + 1);
          audio.current.volume = mutedStates['ultimatetexas'] ? 0 : volumes['ultimatetexas'];
          audio.current.play();
        }, idx * (600/speed) + (600/speed));
      }
    }
  }, [cards]);

  // Render TransitionGroup with CSSTransition for each card
  return (
    <TransitionGroup component={null}>
      {cards.length > 0 &&
        cards.map((card, index) => {
          if (index + 1 > displayedCards) return null;
          return (
            <CSSTransition
              unmountOnExit
              key={`${index}${"texas-slide"}${cardAnimations[index]}`}
              timeout={5000}
              classNames={
                cardAnimations[index] ? cardAnimations[index] : "texas-slide"
              }
            >
              <TexasCardComponent
                key={`$${index}${Math.random()}}`}
                card={card}
                active={true}
                lastCard={index === cards.length - 1}
                cardImages={cardImages}
              />
              {/* <p className="text-white text-bold text-5xl">|@@@@@@@@@@@|</p> */}
            </CSSTransition>
          );
        })}
    </TransitionGroup>
  );
}

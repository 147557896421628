import { useEffect, useState } from "react";
import { Card } from "../../objects/card";

interface CardProps {
  card: Card;
  lastCard?: boolean;
  showCount?: boolean;
  count?: number;
  active?: boolean;
  style?: React.CSSProperties;
  flipping?: boolean;
  cardImages?: any;
  soft?: boolean;
  freeBet?: boolean;
}

const CardComponent: React.FC<CardProps> = ({
  card,
  lastCard,
  showCount,
  count,
  active,
  style,
  flipping,
  cardImages,
  freeBet,
  soft
}) => {
  // Early return if cardImages is not loaded yet
  if (!cardImages) {
    return null; // or return a loading placeholder
  }

  const bgColorClass = count && count > (freeBet ? 22 : 21) ? "bg-red-600" : "bg-primary-500";
  const activeClassImage = active
    ? "w-16 md:w-24 lg:w-28"
    : "w-8 lg:w-20 mr-1 opacity-50";
  const lastCardMargin = lastCard ? "" : "mr-2";
  const activeClassCount = active
    ? "w-10 h-10 lg:w-14 lg:h-14 text-lg lg:text-2xl"
    : "w-6 h-6 lg:w-8 lg:h-8 text-sm lg:text-md opacity-50";

  const getDisplayValue = (value: string | number) => {
    switch(value) {
      case 'Jack': return 'J';
      case 'Queen': return 'Q';
      case 'King': return 'K';
      case 'Ace': return 'A';
      default: return value;
    }
  };

  const getSuitSymbol = (suit: string) => {
    switch (suit.toLowerCase()) {
      case 'hearts': return '♥';
      case 'diamonds': return '♦';
      case 'clubs': return '♣';
      case 'spades': return '♠';
      default: return '';
    }
  };

  const getCardColor = (suit: string) => {
    return ['hearts', 'diamonds'].includes(suit.toLowerCase()) ? 'text-red-600' : 'text-black';
  };

  return (
    <div className="relative z-10" style={style}>
      <div className="relative">
        <img
          src={card.faceup ? cardImages["card_front"].src : cardImages["card_back"].src}
          alt="Card"
          className={`${activeClassImage} ${lastCardMargin} shadow-xl shadow-[#1A2C38] z-10 rounded-md`}
        />
        {card.faceup && (
          <>
          <div className="absolute top-1 left-[0.8rem] flex flex-col items-center" style={{fontFamily: 'Arial, sans-serif'}}>
            <span className={`${getCardColor(card.suit)} mb-[-14px] font-bold text-lg md:text-xl lg:text-3xl`}>
              {getDisplayValue(card.value)}
            </span>
            <span className={`${getCardColor(card.suit)} font-bold text-lg md:text-xl lg:text-4xl`}>
              {getSuitSymbol(card.suit)}
            </span>
          </div>
          <div className={`absolute bottom-1 right-[0.8rem] flex flex-col items-center rotate-180 ${lastCard ? '': 'mr-2'}`} style={{fontFamily: 'Arial, sans-serif'}}>
          <span className={`${getCardColor(card.suit)} mb-[-14px] font-bold text-lg md:text-xl lg:text-3xl`}>
            {getDisplayValue(card.value)}
          </span>
          <span className={`${getCardColor(card.suit)} font-bold text-lg md:text-xl lg:text-4xl`}>
              {getSuitSymbol(card.suit)}
            </span>
          </div>  
          </>
        )}
      </div>
      {lastCard && showCount && card.faceup && (
        <div
          className={`absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 ${bgColorClass} rounded-full ${activeClassCount} flex items-center justify-center text-white font-bold select-none`}
        >
          <span className="flex items-center justify-center w-full h-full mb-1">
            {soft && count<21 ? `${count-10}/${count}` : count}
          </span>
        </div>
      )}
    </div>
  );
};

export default CardComponent;